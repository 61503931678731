import type { LocationDescriptorObject } from 'history';

/**
 * TpAuthStatus allows a simplified way of knowing whether a user is:
 *
 * - in an unknown state
 * - definitely a guest
 * - definitely authenticated but not verified
 * - definitely authenticated
 */
export enum TpAuthStatus {
  authenticated = 'authenticated',
  authenticatedNotVerified = 'authenticatedNotVerified',
  guest = 'guest',
  unknown = 'unknown',
}

export type TpAccessControlRedirect = {
  to: LocationDescriptorObject<{
    from: string;
  }>;
};

export type TpAccessControlLoading = {
  message: string;
};
