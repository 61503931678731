import { CurrencyCode } from '@noah-labs/shared-schema-gql';
import { getConfig } from '../config';

function isCurrencyCode(currencyCode: string): currencyCode is CurrencyCode {
  return Object.values(CurrencyCode).includes(currencyCode as CurrencyCode);
}

export function getCurrenciesFromConfig(): CurrencyCode[] {
  const { currencies } = getConfig();
  const currencyKeys = Object.keys(currencies.configurations);
  const currencySet = new Set<CurrencyCode>();

  currencyKeys.forEach((key) => {
    const currencyCode = key.split('/')[1];
    if (isCurrencyCode(currencyCode)) {
      currencySet.add(currencyCode);
    }
  });

  return Array.from(currencySet);
}
