import { styled } from '@mui/material/styles';

export type PpStatusBadge = {
  Icon: React.ReactElement;
  badgeColor: string;
  badgeSize: number;
};

const StyledStatusBadge = styled('div')<Omit<PpStatusBadge, 'Icon'>>(
  ({ badgeColor, badgeSize, theme }) => ({
    '& svg': {
      display: 'block',
      height: '100%',
      width: '100%',
    },
    color: badgeColor,
    fontSize: theme.spacing(badgeSize),
    height: '1em',
    lineHeight: '1em',
    width: '1em',
  }),
);

export function StatusBadge({ badgeColor, badgeSize, Icon }: PpStatusBadge): React.ReactElement {
  return (
    <StyledStatusBadge badgeColor={badgeColor} badgeSize={badgeSize}>
      {Icon}
    </StyledStatusBadge>
  );
}
