import React from 'react';
import type { LocationDescriptor, LocationDescriptorObject } from 'history';

type TpRoutingFn = <T = unknown>(to: string | LocationDescriptor<T>) => void;

export type CtxRouter = {
  createHref: <T>(to: LocationDescriptorObject<T>) => string;
  goBack: () => void;
  goForward: () => void;
  pathname: string;
  push: TpRoutingFn;
  replace: TpRoutingFn;
  search: string;
  useOnRouteChange: (onRouteChange: () => void) => void;
  useParams: () => Record<string, string | string[] | undefined>;
};

// Create the context with a default undefined value
export const RouterContext = React.createContext<CtxRouter | undefined>(undefined);
