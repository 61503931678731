import { css } from '@emotion/react';
import type { ListItemButtonProps, ListItemProps, SxProps, Theme } from '@mui/material';
import { ListItem, ListItemButton } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { getInteractiveProps } from '../links/getInteractiveProps';
import { ElevatedCard } from './ElevatedCard';

export type PpElevatedCardListItem = ListItemProps & {
  buttonAsRole?: boolean;
  buttonSx?: SxProps<Theme>;
  dataQa?: string;
  dense?: boolean;
  disabled?: boolean;
  elevation?: number;
  href?: string;
  onClick?: ListItemButtonProps['onClick'];
  spacing?: number;
  sx?: SxProps<Theme>;
};

export function ElevatedCardListItem({
  buttonSx,
  children,
  dataQa,
  dense,
  disabled = false,
  elevation,
  href,
  onClick,
  spacing,
  sx,
  ...rest
}: PpElevatedCardListItem): React.ReactElement {
  const theme = useTheme();

  const interactiveProps = getInteractiveProps({ href, onClick });

  const styles = css`
    &:not(:last-child) {
      margin-bottom: ${theme.spacing(spacing || 1.5)};
    }
  `;

  return (
    <ListItem disableGutters disablePadding css={styles} data-qa={dataQa} {...rest}>
      <ElevatedCard elevation={elevation} sx={sx}>
        <ListItemButton
          {...interactiveProps}
          disabled={disabled}
          href={href}
          sx={{
            width: 1,
            ...{ p: dense ? 1 : 2 },
            ...buttonSx,
          }}
          onClick={onClick}
        >
          {children}
        </ListItemButton>
      </ElevatedCard>
    </ListItem>
  );
}
