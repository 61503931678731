import { CurrencyCode, Network } from '@noah-labs/shared-schema-gql';
import { duration } from '@noah-labs/shared-util-vanilla';
import type { TpCryptoNetwork } from './types';

export const cryptoNetworks: Record<Network, TpCryptoNetwork> = {
  Bitcoin: {
    id: Network.Bitcoin,
    isTestNetwork: false,
    label: Network.Bitcoin,
    nativeCurrency: CurrencyCode.BTC,
    networkFees: 'medium',
    processingTime: duration.hours(1),
  },
  BitcoinTest: {
    description: 'Testnet',
    id: Network.BitcoinTest,
    isTestNetwork: true,
    label: Network.Bitcoin,
    nativeCurrency: CurrencyCode.BTC_TEST,
    networkFees: 'medium',
    processingTime: duration.hours(1),
  },
  Ethereum: {
    description: 'ERC-20',
    id: Network.Ethereum,
    isTestNetwork: false,
    label: Network.Ethereum,
    nativeCurrency: CurrencyCode.ETH,
    networkFees: 'high',
    processingTime: duration.minutes(5),
  },
  EthereumTestSepolia: {
    description: 'ERC-20 Testnet',
    id: Network.EthereumTestSepolia,
    isTestNetwork: true,
    label: Network.Ethereum,
    nativeCurrency: CurrencyCode.ETH_TEST_SEPOLIA,
    networkFees: 'high',
    processingTime: duration.minutes(5),
  },
  Lightning: {
    id: Network.Lightning,
    isTestNetwork: false,
    label: Network.Lightning,
    nativeCurrency: CurrencyCode.BTC,
    networkFees: 'none',
    processingTime: duration.seconds(5),
  },
  LightningTest: {
    description: 'Testnet',
    id: Network.LightningTest,
    isTestNetwork: true,
    label: Network.Lightning,
    nativeCurrency: CurrencyCode.BTC_TEST,
    networkFees: 'none',
    processingTime: duration.seconds(5),
  },
  OffNetwork: {
    id: Network.OffNetwork,
    isTestNetwork: null,
    label: Network.OffNetwork,
    nativeCurrency: null,
    networkFees: 'none',
    processingTime: 0,
  },
  PolygonPos: {
    id: Network.PolygonPos,
    isTestNetwork: false,
    label: 'Polygon PoS',
    nativeCurrency: CurrencyCode.MATIC,
    networkFees: 'low',
    processingTime: duration.minutes(1),
  },
  PolygonTestAmoy: {
    description: 'Testnet',
    id: Network.PolygonTestAmoy,
    isTestNetwork: true,
    label: 'Polygon PoS',
    nativeCurrency: CurrencyCode.MATIC_TEST,
    networkFees: 'low',
    processingTime: duration.minutes(1),
  },
  PolygonTestMumbai: {
    description: 'Testnet',
    id: Network.PolygonTestMumbai,
    isTestNetwork: true,
    label: 'Polygon PoS',
    nativeCurrency: CurrencyCode.MATIC_TEST,
    networkFees: 'low',
    processingTime: duration.minutes(1),
  },
  Tron: {
    id: Network.Tron,
    isTestNetwork: false,
    label: Network.Tron,
    nativeCurrency: CurrencyCode.TRX,
    networkFees: 'low',
    processingTime: duration.minutes(1),
  },
  TronTestShasta: {
    description: 'Testnet',
    id: Network.TronTestShasta,
    isTestNetwork: true,
    label: Network.Tron,
    nativeCurrency: CurrencyCode.TRX_TEST,
    networkFees: 'low',
    processingTime: duration.minutes(1),
  },
};
