import type { StoryObj } from '@storybook/react';
import { mockLNAddressData } from '../mock';
import type { PpAddress } from './AddressWithCopy';
import { AddressWithCopy as AddressWithCopyComponent } from './AddressWithCopy';

const description = `
- for displaying addresses
`;

export default {
  component: AddressWithCopyComponent,
  parameters: {
    docs: {
      description: {
        component: description,
      },
    },
  },
};

export const Wrapped: StoryObj<PpAddress> = {
  args: {
    address: mockLNAddressData().address,
    noWrap: false,
  },
};

export const NotWrapped: StoryObj<PpAddress> = {
  args: {
    address: mockLNAddressData().address,
    noWrap: true,
  },
};

export const Text: StoryObj<PpAddress> = {
  args: {
    address: mockLNAddressData().address,
    noWrap: true,
    variant: 'text',
  },
};
