/* eslint-disable react/no-array-index-key */
import { Fragment } from 'react';
import { Avatar, Skeleton } from '@mui/material';
import { ElevatedCardListItem } from '../cards/ElevatedCardListItem';
import { List } from './List';
import {
  ListItem,
  ListItemContent,
  ListItemIcon,
  ListItemPrimaryText,
  ListItemSecondaryText,
  ListItemStartContent,
} from './ListItem';

type PpSkeletonQuestList = {
  rows?: number;
};

export function SkeletonQuestList({ rows = 2 }: PpSkeletonQuestList): React.ReactElement {
  return (
    <Fragment>
      {new Array(rows).fill(null).map((_, index) => (
        <ElevatedCardListItem key={index} dataQa="skeleton">
          <List disablePadding>
            <ListItem>
              <ListItemContent padding={0}>
                <ListItemIcon>
                  <Avatar variant="square">
                    <Skeleton variant="rectangular" />
                  </Avatar>
                </ListItemIcon>
                <ListItemStartContent>
                  <ListItemPrimaryText>
                    <Skeleton sx={{ px: '6rem' }} variant="text" />
                  </ListItemPrimaryText>
                  <ListItemSecondaryText>
                    <Skeleton variant="text" />
                  </ListItemSecondaryText>
                </ListItemStartContent>
              </ListItemContent>
            </ListItem>
          </List>
        </ElevatedCardListItem>
      ))}
    </Fragment>
  );
}
