import type { ReactElement } from 'react';
import type { StackProps } from '@mui/material';
// eslint-disable-next-line no-restricted-imports
import { List as MuiList, Stack } from '@mui/material';
import type { PpWC } from '@noah-labs/fe-shared-ui-shared';

type PpListWithSpacing = {
  disablePadding?: boolean;
} & Pick<StackProps, 'divider' | 'spacing' | 'sx' | 'direction' | 'useFlexGap'> &
  PpWC;

export function List({
  children,
  direction = 'column',
  disablePadding = false,
  divider,
  spacing,
  sx,
  useFlexGap,
}: PpListWithSpacing): ReactElement {
  return (
    <Stack
      component={MuiList}
      direction={direction}
      disablePadding={disablePadding}
      divider={divider}
      spacing={spacing}
      sx={sx}
      useFlexGap={useFlexGap}
    >
      {children}
    </Stack>
  );
}
