import bitcoin from '@noah-labs/fe-shared-ui-assets/svg/icons/bitcoin.svg';
import ethereum from '@noah-labs/fe-shared-ui-assets/svg/icons/ethereum.svg';
import lightningCircle from '@noah-labs/fe-shared-ui-assets/svg/icons/lightning-circle.svg';
import noahcircle from '@noah-labs/fe-shared-ui-assets/svg/icons/noah-circle.svg';
import polygon from '@noah-labs/fe-shared-ui-assets/svg/icons/polygon.svg';
import tron from '@noah-labs/fe-shared-ui-assets/svg/icons/tron.svg';
import { assetPaths } from '@noah-labs/shared-constants';
import { cryptoNetworks } from '@noah-labs/shared-currencies';
import type { Network } from '@noah-labs/shared-schema-gql';
import type { TpCryptoNetworkUi, TpExtraCryptoNetworkUi } from './types';

const bitcoinStyles = {
  border: {
    backgroundImage:
      'linear-gradient(90deg, rgba(243, 208, 207, 0.75) 22%, rgba(229, 14, 14, 1) 100%)',
  },
  card: {
    backgroundImage: `url(${assetPaths.images}/bg-images/gradient-small.png)`,
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
  },
};

const ethereumStyles = {
  border: {
    backgroundImage:
      'linear-gradient(90deg, rgba(226, 237, 250, 1) 0%, rgba(62, 131, 207, 1) 100%)',
  },
  card: {
    backgroundImage: 'linear-gradient(66deg, #75aeec 4.57%, #629ee0 32.51%, #2775ca 59.4%)',
  },
};

const polygonStyles = {
  border: {
    backgroundImage:
      'linear-gradient(90deg, rgba(237, 212, 243, 1) 0%, rgba(140, 84, 231, 1) 100%)',
  },
  card: {
    backgroundImage: 'linear-gradient(66deg, #a628c4 4.57%, #7f40e4 59.4%)',
  },
};

const tronStyles = {
  border: {
    backgroundImage: 'linear-gradient(90deg, rgba(255, 6, 10, 0.75) 22%, rgba(255, 6, 10, 1) 100%)',
  },
  card: {
    backgroundColor: '#dc062b',
  },
};

function createLink(base: string, publicId: string | null | undefined): string | undefined {
  return publicId ? `${base}/${publicId}` : undefined;
}

const extraCryptoNetworkUi: Record<Network, TpExtraCryptoNetworkUi> = {
  Bitcoin: {
    getPublicTxUrl: (publicId) => createLink('https://www.oklink.com/btc/tx', publicId),
    styles: bitcoinStyles,
    svg: bitcoin,
  },
  BitcoinTest: {
    getPublicTxUrl: (publicId) => createLink('https://live.blockcypher.com/btc-test/tx', publicId),
    styles: bitcoinStyles,
    svg: bitcoin,
  },
  Ethereum: {
    getPublicTxUrl: (publicId) => createLink('https://etherscan.io/tx', publicId),
    styles: ethereumStyles,
    svg: ethereum,
  },
  EthereumTestSepolia: {
    getPublicTxUrl: (publicId) => createLink('https://sepolia.etherscan.io/tx', publicId),
    styles: ethereumStyles,
    svg: ethereum,
  },
  Lightning: {
    getPublicTxUrl: (_publicId) => undefined,
    styles: bitcoinStyles,
    svg: lightningCircle,
  },
  LightningTest: {
    getPublicTxUrl: (_publicId) => undefined,
    styles: bitcoinStyles,
    svg: lightningCircle,
  },
  // TODO (cs): Not quite sure how we'll work with this so may need a different icon
  OffNetwork: {
    getPublicTxUrl: (_publicId) => undefined,
    styles: bitcoinStyles,
    svg: noahcircle,
  },
  PolygonPos: {
    getPublicTxUrl: (publicId) => createLink('https://polygonscan.com/tx', publicId),
    styles: polygonStyles,
    svg: polygon,
  },
  PolygonTestAmoy: {
    getPublicTxUrl: (publicID) => createLink('https://amoy.polygonscan.com/tx', publicID),
    styles: polygonStyles,
    svg: polygon,
  },
  PolygonTestMumbai: {
    getPublicTxUrl: (publicId) => createLink('https://mumbai.polygonscan.com/tx', publicId),
    styles: polygonStyles,
    svg: polygon,
  },
  Tron: {
    getPublicTxUrl: (publicId) => createLink('https://www.oklink.com/trx/tx', publicId),
    styles: tronStyles,
    svg: tron,
  },
  TronTestShasta: {
    getPublicTxUrl: (_publicId) => undefined,
    styles: tronStyles,
    svg: tron,
  },
};

export const cryptoNetworksUi = Object.entries(cryptoNetworks).reduce(
  (acc, [key, value]) => {
    const valueWithIcons: TpCryptoNetworkUi = {
      ...value,
      ...extraCryptoNetworkUi[value.id],
    };
    acc[key as Network] = valueWithIcons;
    return acc;
  },
  {} as Record<Network, TpCryptoNetworkUi>,
);

export function cryptoNetworkFromId(id: Network): TpCryptoNetworkUi {
  return cryptoNetworksUi[id];
}
