import { getCurrenciesFromConfig } from '@noah-labs/fe-shared-data-access-wallet';
import { TpCheckoutPaymentStatus, TpCheckoutPayoutStatus } from '@noah-labs/fe-shared-util-shared';
import { AccountType } from '@noah-labs/shared-schema-gql';

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
function createWalletRoutes() {
  // URL FORMAT
  // /wallet/<CurrencyCode>/<AccountType>/<Action>/<Step>

  /**
   * Ensure that only the routes we specify in our enums are available,
   * so that we know that if the route renders, it must be one of enums
   */

  const currenciesFromConfig = getCurrenciesFromConfig();
  const walletBase = '/wallet';
  const currencyCodes = currenciesFromConfig.join('|').toLowerCase();
  const accountTypes = Object.keys(AccountType).join('|').toLowerCase();
  const paymentStatuses = Object.keys(TpCheckoutPaymentStatus).join('|').toLowerCase();
  const payoutStatuses = Object.keys(TpCheckoutPayoutStatus).join('|').toLowerCase();
  const accountBase = `${walletBase}/:currencyCode(${currencyCodes})/:accountType(${accountTypes})`;

  return {
    account: {
      base: accountBase,
      transactions: `${accountBase}/transactions/:id`,
    },
    address: {
      base: `${accountBase}/address`,
      scan: `${accountBase}/address/scan`,
      text: `${accountBase}/address/text-entry`,
    },
    base: '/wallet',
    buy: {
      base: `${accountBase}/buy`,
      complete: `${accountBase}/buy/complete/:paymentStatus(${paymentStatuses})`,
      confirm: `${accountBase}/buy/confirm`,
      enterAmount: `${accountBase}/buy`,
      payment: `${accountBase}/buy/payment`,
    },
    lightningSend: {
      base: `${accountBase}/lightning-send`,
      complete: `${accountBase}/lightning-send/complete`,
      confirm: `${accountBase}/lightning-send/confirm`,
      enterAmount: `${accountBase}/lightning-send`,
      invoiceExpired: `${accountBase}/lightning-send/invoice-expired`,
      request: `${accountBase}/lightning-send/request/:source/:encodedSendParams`,
    },
    receive: {
      base: `${accountBase}/receive`,
      lightning: {
        enterAmount: `${accountBase}/receive/lightning`,
        viewInvoice: `${accountBase}/receive/lightning/view-invoice`,
      },
      network: `${accountBase}/receive/network`,
    },
    selectCrypto: {
      base: '/wallet/select-crypto',
      receive: '/wallet/select-crypto/receive',
      send: '/wallet/select-crypto/send',
    },
    sell: {
      base: `${accountBase}/sell`,
      complete: `${accountBase}/sell/complete/:payoutStatus(${payoutStatuses})`,
      confirm: `${accountBase}/sell/confirm`,
      enterAccountDetails: `${accountBase}/sell/payment/bank`,
      enterAmount: `${accountBase}/sell/enter-amount`,
      enterPaymentInfo: `${accountBase}/sell/payment/card`,
      payoutMethod: `${accountBase}/sell`,
    },
    withdraw: {
      base: `${accountBase}/withdraw`,
      complete: `${accountBase}/withdraw/complete`,
      confirm: `${accountBase}/withdraw/confirm`,
      enterAmount: `${accountBase}/withdraw`,
      network: `${accountBase}/withdraw/network`,
    },
  };
}

let walletRoutesCached: ReturnType<typeof createWalletRoutes> | undefined;

export function walletRoutes(): ReturnType<typeof createWalletRoutes> {
  if (walletRoutesCached) {
    return walletRoutesCached;
  }
  return createWalletRoutes();
}
