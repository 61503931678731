import { FiatCurrencyCode } from '@noah-labs/shared-schema-gql';
import type { TpFiatCurrency } from './types';

// TODO (cs): use countries-list package or similar to get icons etc
export const fiatCurrencies: Record<FiatCurrencyCode, TpFiatCurrency> = {
  AED: {} as TpFiatCurrency,
  ARS: {
    code: FiatCurrencyCode.ARS,
    decimals: 2,
    label: 'Argentine Peso',
    symbol: '$',
  },
  AUD: {} as TpFiatCurrency,
  BDT: {} as TpFiatCurrency,
  BHD: {} as TpFiatCurrency,
  BMD: {} as TpFiatCurrency,
  BRL: {
    code: FiatCurrencyCode.BRL,
    decimals: 2,
    label: 'Brazilian Real',
    symbol: 'R$',
  },
  CAD: {} as TpFiatCurrency,
  CHF: {} as TpFiatCurrency,
  CLP: {} as TpFiatCurrency,
  CNY: {} as TpFiatCurrency,
  COP: {} as TpFiatCurrency,
  CZK: {} as TpFiatCurrency,
  DKK: {} as TpFiatCurrency,
  EUR: {
    code: FiatCurrencyCode.EUR,
    decimals: 2,
    label: 'European Union euro',
    symbol: '€',
  },
  GBP: {
    code: FiatCurrencyCode.GBP,
    decimals: 2,
    label: 'British Pound Sterling',
    symbol: '£',
  },
  HKD: {} as TpFiatCurrency,
  HUF: {} as TpFiatCurrency,
  IDR: {} as TpFiatCurrency,
  ILS: {} as TpFiatCurrency,
  INR: {} as TpFiatCurrency,
  JPY: {} as TpFiatCurrency,
  KRW: {} as TpFiatCurrency,
  KWD: {} as TpFiatCurrency,
  LKR: {} as TpFiatCurrency,
  MMK: {} as TpFiatCurrency,
  MXN: {
    code: FiatCurrencyCode.MXN,
    decimals: 2,
    label: 'Mexican Peso',
    symbol: '$',
  },
  MYR: {} as TpFiatCurrency,
  NGN: {
    code: FiatCurrencyCode.NGN,
    decimals: 2,
    label: 'Nigerian Naira',
    symbol: '₦',
  },
  NOK: {} as TpFiatCurrency,
  NZD: {} as TpFiatCurrency,
  PHP: {} as TpFiatCurrency,
  PKR: {} as TpFiatCurrency,
  PLN: {} as TpFiatCurrency,
  RUB: {} as TpFiatCurrency,
  SAR: {} as TpFiatCurrency,
  SEK: {} as TpFiatCurrency,
  SGD: {} as TpFiatCurrency,
  THB: {} as TpFiatCurrency,
  TRY: {} as TpFiatCurrency,
  TWD: {} as TpFiatCurrency,
  UAH: {} as TpFiatCurrency,
  USD: {
    code: FiatCurrencyCode.USD,
    decimals: 2,
    label: 'United States Dollar',
    symbol: '$',
  },
  VEF: {} as TpFiatCurrency,
  VND: {} as TpFiatCurrency,
  ZAR: {
    code: FiatCurrencyCode.ZAR,
    decimals: 2,
    label: 'South African Rand',
    symbol: 'R',
  },
};
