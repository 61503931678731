import type { UiNode, UiNodeInputAttributes, UiText } from '@ory/client';
import type { AxiosError } from 'axios';

export type OryContinueWithVerification = { action: 'show_verification_ui'; flow: { id: string } };

export type OryRedirectBrowserError = AxiosError<{ redirect_browser_to: string }> & {
  response: object;
};

export type OrySessionRefreshRequiredError = AxiosError & {
  response: object;
};

export type OryNoSecurityKeyError = AxiosError & {
  response: object;
};

export type OryContinueWith = OryContinueWithVerification | { action: string };

export type OryUpdateFlowError = AxiosError<{
  use_flow_id: string;
}> & {
  response: object;
};

export type SocialProvider = 'google' | 'apple';

export enum AuthErrorType {
  IdentifierExists = 'IdentifierExists',
  Unspecified = 'Unspecified',
}

export type OryUiTextError = UiText & { type: 'error' };

export type TpSignUpPayload = {
  referralCode: string | null;
  sessionKey: string | undefined;
};

export type AuthError = {
  message: string;
  type: string;
};

export type AuthSetError<T> = (name: keyof T | `root.${string}` | 'root', error: AuthError) => void;

export type InputUiNode<T> = UiNode & {
  attributes: Omit<UiNodeInputAttributes, 'value'> & { value: T };
};
