export enum TpCheckoutPaymentStatus {
  failure = 'failure',
  pending = 'pending',
  success = 'success',
}

export enum TpCheckoutPayoutStatus {
  failure = 'failure',
  pending = 'pending',
  success = 'success',
}
