import { Fragment } from 'react';
import { useAutocomplete } from '@mui/material';
import { createFilterOptions } from '@mui/material/Autocomplete';
import { AvatarIconFromSvg } from '../images/Avatar';
import { List } from '../lists/List';
import {
  ListItem,
  ListItemButton,
  ListItemCard,
  ListItemContent,
  ListItemIcon,
  ListItemPrimaryText,
  ListItemSecondaryText,
  ListItemStartContent,
} from '../lists/ListItem';
import { ACContent, ACNoOptions, ACTextField } from './atoms/SelectAtoms';
import { SkeletonSelectList } from './atoms/SkeletonSelectList';

export type TpSelectOption = {
  currency?: string;
  label: string;
  secondaryLabel?: string;
  svg?: TpReactSvg | null;
  symbol?: string;
  value: string;
};

export type PpSearchSelect = {
  id: string;
  loading: boolean;
  // minimum number of items to display search bar
  minForSearch?: number;
  onChange: (value: TpSelectOption | null) => void;
  options: Array<TpSelectOption> | undefined;
};

const emptyOptions: Array<TpSelectOption> = [];
/**
 * TODO: investigate slow opening performance - is related to the size of the list
 * Tried memo-izing the ListItemButton parts but it didn't help
 */
export function SearchSelect({
  id,
  loading,
  minForSearch = 8,
  onChange,
  options = emptyOptions,
}: PpSearchSelect): React.ReactElement | null {
  const filterOptions = createFilterOptions<TpSelectOption>({
    stringify: (option): string => [option.label, option.value].join(''),
  });

  const { getInputProps, getListboxProps, getOptionProps, getRootProps, groupedOptions } =
    useAutocomplete({
      clearOnBlur: false,
      disableCloseOnSelect: false,
      filterOptions,
      id: `${id}-select`,
      onChange: (_, newValue): void => {
        onChange(newValue);
      },
      open: true,
      options,
    });

  return (
    <Fragment>
      <ACTextField
        dataQa={`${id}-search-input`}
        hide={options.length < minForSearch}
        inputProps={getInputProps()}
        rootProps={getRootProps()}
      />
      {loading ? (
        <SkeletonSelectList />
      ) : (
        <ACContent>
          {groupedOptions.length > 0 ? (
            <List {...getListboxProps()} spacing={0.5}>
              {(groupedOptions as Array<TpSelectOption>).map((option, index) => (
                // key is in optionProps
                // eslint-disable-next-line react/jsx-key
                <ListItem
                  {...getOptionProps({ index, option })}
                  disablePadding
                  component="li"
                  data-qa={`${id}-${option.value}`}
                >
                  <ListItemButton>
                    <ListItemCard>
                      <ListItemContent>
                        <ListItemIcon>
                          {option.svg ? <AvatarIconFromSvg svg={option.svg} /> : option.symbol}
                        </ListItemIcon>
                        <ListItemStartContent>
                          <ListItemPrimaryText>{option.label}</ListItemPrimaryText>
                          {option.secondaryLabel && (
                            <ListItemSecondaryText>{option.secondaryLabel}</ListItemSecondaryText>
                          )}
                        </ListItemStartContent>
                      </ListItemContent>
                    </ListItemCard>
                  </ListItemButton>
                </ListItem>
              ))}
            </List>
          ) : (
            <ACNoOptions />
          )}
        </ACContent>
      )}
    </Fragment>
  );
}
