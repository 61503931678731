import { CurrencyUnit } from '@noah-labs/shared-schema-gql';
import type { StoryObj } from '@storybook/react';
import { CurrencySwitchButton } from '../buttons/CurrencySwitchButton';
import { mockBTC, mockUSD } from '../mock';
import { CryptoAmount } from '../numbers/CryptoAmount';
import { FiatAmount } from '../numbers/FiatAmount';
import type { PpBalance } from './Balance';
import { Balance as BalanceComponent } from './Balance';

const description = `
- balance component
- primary variant is used on wallet home and transaction details screens
- secondary variant is used on invoce details list
- for correct amount format use specific amount components \`/src/numbers/\` folder
  - for fiat amount - \`<FiatAmount amount="123" currency={mockUSD} />\`
  - for crypto amount - \`<CryptoAmount amount="234" currency={mockBTC} currencyUnit={CurrencyUnit.Default} />\`
`;

export default {
  component: BalanceComponent,
  parameters: {
    docs: {
      description: {
        component: description,
      },
    },
  },
};

const commonArgs = {
  PrimaryAmountSlot: <FiatAmount amount="123" currency={mockUSD} />,
  SecondaryAmountSlot: (
    <CryptoAmount amount="234" currency={mockBTC} currencyUnit={CurrencyUnit.Default} />
  ),
};

export const Balance: StoryObj<PpBalance> = {
  args: {
    ...commonArgs,
  },
};

export const WithLabel: StoryObj<PpBalance> = {
  args: {
    ...commonArgs,
    label: 'Your balance',
  },
};

export const WithSwitch: StoryObj<PpBalance> = {
  args: {
    ...commonArgs,
    IconSlot: <CurrencySwitchButton />,
    label: 'Your balance',
  },
};
