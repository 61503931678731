import { Fragment } from 'react';
import { AlertLink } from '@noah-labs/fe-shared-ui-components';
import type { TpPushAlert } from '@noah-labs/fe-shared-ui-components';
import { authRoutes } from '@noah-labs/fe-shared-util-routes';

function AlertError(key: string, error: React.ReactNode): TpPushAlert {
  return {
    autoHideDuration: 3000,
    key,
    message: error,
    preventDuplicate: true,
    severity: 'error',
  };
}

/**
 * Errors
 */
export const AlertAuthUnknown: TpPushAlert = AlertError(
  'authUnknown',
  'Sorry, something went wrong.',
);

export const AlertSignUpEmailAlreadyExists: TpPushAlert = AlertError(
  'signUpEmailAlreadyExists',
  <Fragment>
    This email address is already associated with a NOAH account. If this is your account please{' '}
    <AlertLink href={authRoutes.signIn}>log in here</AlertLink>.
  </Fragment>,
);

export function AlertMessage(message: string | undefined): TpPushAlert {
  return AlertError('authErrorMessage', message);
}

export const AlertEmailNotVerified: TpPushAlert = AlertError(
  'authEmailNotVerified',
  'Please verify your email before logging in.',
);
