import {
  cryptoCurrencyFromCode,
  cryptoNetworkFromId,
  fiatCurrencyFromCode,
} from '@noah-labs/fe-shared-ui-shared';
import { AccountType, CurrencyCode, FiatCurrencyCode, Network } from '@noah-labs/shared-schema-gql';
import { duration } from '@noah-labs/shared-util-vanilla';
import type { TpSelectOption } from './forms/SearchSelect';
import type {
  TpBitcoinAddressData,
  TpLightningAddressData,
  TpUsdcAddressData,
  TpUsdtAddressData,
} from './tools/parseAddressData';

// only used in stories
export const mockBTC = cryptoCurrencyFromCode(CurrencyCode.BTC);
export const mockUSDC = cryptoCurrencyFromCode(CurrencyCode.USDC);
export const mockUSDT = cryptoCurrencyFromCode(CurrencyCode.USDT);
export const mockUSD = fiatCurrencyFromCode(FiatCurrencyCode.USD);

export const mockAccounts = [
  {
    AccountType: AccountType.Current,
    Balance: { Available: '123' },
    CurrencyCode: CurrencyCode.BTC,
    ID: '1',
  },
  {
    AccountType: AccountType.Current,
    Balance: { Available: '891' },
    CurrencyCode: CurrencyCode.ETH,
    ID: '2',
  },
  {
    AccountType: AccountType.Current,
    Balance: { Available: '245' },
    CurrencyCode: CurrencyCode.USDC,
    ID: '3',
  },
  {
    AccountType: AccountType.Current,
    Balance: { Available: '90' },
    CurrencyCode: CurrencyCode.USDT,
    ID: '4',
  },
];

export const mockCountries: TpSelectOption[] = [
  {
    currency: 'EUR',
    label: 'Andorra',
    symbol: '🇦🇩',
    value: 'AD',
  },
  {
    currency: 'EUR',
    label: 'France',
    symbol: '🇫🇷',
    value: 'FR',
  },
  {
    currency: 'YER',
    label: 'Yemen',
    symbol: '🇾🇪',
    value: 'YE',
  },
  {
    currency: 'USD',
    label: 'United States',
    symbol: '🇺🇸',
    value: 'US',
  },
];

// should be a function because of the Date.now() line
export function mockBitrefillLNInvoiceData(): TpLightningAddressData {
  return {
    address:
      'lnbc58080n1p3u00zmpp5nrcp3rgxt6vlal6m5jsu2ggqlu085d036uefj6kkyz8gt57jggxsdz2gf5hgun9ve5kcmpq8yekydpcvdjngttyxccnxtf58qcrxtfevc6rvttyxucxxvny8ymxyetyvycqzx7xqrp9ssp5gertnzw9pxx8y3q6f20agt8kzaqvzspwl5ha2u9hf9q0flneha6q9qyyssquzzhsdsukreckj8qutjqgyt5s89mespqav9u96526s54h6tcghv8uekvty5upmgqk58ttgmcgjugl0px4j3mjujusseqrmwxkpp5cysq5emg8f',
    addressType: 'lnbc',
    amount: '0.01',
    currencyCode: CurrencyCode.BTC,
    description: 'Bitrefill 93b48ce4-d613-4803-9f46-d70c2d96beda',
    expiryTime: Date.now() + duration.minutes(20),
    network: Network.Lightning,
    processingTime: duration.seconds(30),
    svg: cryptoNetworkFromId(Network.Lightning).svg,
  };
}

// should be a function because of the Date.now() line
export function mockLNAddressData(): TpLightningAddressData {
  return {
    address:
      'lntb67180n1p3gav6khp58yjmdan79s6qqdhdzgynm4zwqd5d7xmw5fk98klysy043l2ahrqspp5huncfk5aamvpmhw2uhwssjtv6gp60c6m40ead3hl98g26n0n2awqxqrrsssp5m6kmam774klwlh4dhmhaatd7al02m0h0m6kmam774klwlh4dhmhs9qypqsqcqpfcey7c28qvzlx0mhuf6wfal3257qxtwpw7vupqw9lmaufz29zhf7xavzh3xs0yjlvc3yqs0fgwvfek3h8kptsp0rvkw24y5f3k32x2qqpu9h3yz',
    addressType: 'lnbc',
    amount: '0.02',
    currencyCode: CurrencyCode.BTC,
    description: 'Starbucks matcha latté',
    expiryTime: Date.now() + duration.minutes(10),
    network: Network.Lightning,
    processingTime: duration.seconds(30),
    svg: cryptoNetworkFromId(Network.Lightning).svg,
  };
}

export const mockBTCAddressData: TpBitcoinAddressData = {
  address: '3FZbgi29cpjq2GjdwV8eyHuJJnkLtktZc5',
  currencyCode: CurrencyCode.BTC,
  network: Network.Bitcoin,
  processingTime: duration.minutes(30),
  svg: mockBTC.svg,
};

export const mockUSDCAddressData = {
  address: '0xE3Cc8Aff345527b48Db9f398eA2a7FBbC90CB37b',
  currencyCode: CurrencyCode.USDC,
  network: Network.Ethereum,
  processingTime: duration.minutes(30),
  svg: mockUSDC.svg,
} satisfies TpUsdcAddressData;

export const mockUSDTAddressData = {
  address: '0x7C4E1A846Ac3fF85d2c13c85eF9f67b9AF272221',
  currencyCode: CurrencyCode.USDT,
  network: Network.Ethereum,
  processingTime: duration.minutes(30),
  svg: mockUSDT.svg,
} satisfies TpUsdtAddressData;

export const mockUserProfile = {
  Email: 'test@test.com',
  PersonalName: {
    FirstName: 'John',
    LastName: 'Doe',
    MiddleName: 'James',
  },
  PhoneNumber: '+23423984575',
  PrimaryAddress: {
    Country: 'GB',
  },
  UsernameDisplay: 'DisplayUsername',
};
