import type { FullName } from '@noah-labs/shared-schema-gql';
import { createInternetID, isUndefinedOrNull } from '@noah-labs/shared-util-vanilla';
import { getConfig } from './config';

export function getUserFullDisplayName(fullName: FullName | null | undefined): string | undefined {
  if (isUndefinedOrNull(fullName)) {
    return undefined;
  }

  const { FirstName, LastName, MiddleName } = fullName;

  return [FirstName, MiddleName, LastName].filter(Boolean).join(' ');
}

export function getUserLnAddress(userNameDisplay: string): string {
  const { lnDomain } = getConfig();
  return createInternetID(userNameDisplay, lnDomain);
}
