import type { CardProps } from '@mui/material';
import { Card } from '@mui/material';

export function ElevatedCard({
  children,
  className,
  component,
  elevation,
  sx,
}: Pick<
  CardProps,
  'children' | 'className' | 'component' | 'elevation' | 'sx'
>): React.ReactElement {
  return (
    <Card
      raised
      className={className}
      // @ts-expect-error - usual MUI false positive
      component={component}
      elevation={elevation}
      sx={{ width: '100%', ...sx }}
      variant="elevation"
    >
      {children}
    </Card>
  );
}
