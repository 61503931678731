/**
 * Assets
 */
export const assetsHost = 'assets.noah.com';
const assetsBase = `https://${assetsHost}`;
export const assetPaths = {
  fonts: `${assetsBase}/fonts`,
  host: assetsHost,
  images: `${assetsBase}/images`,
} as const;

/**
 * Auth
 */
export const authPaths = {
  base: '/auth',
  error: '/auth/error',
  recovery: '/auth/recovery',
  signedOut: '/auth/signedout',
  signIn: '/auth/signin',
  signOut: '/auth/signout',
  signUp: '/auth/signup',
  verify: '/auth/verify',
} as const;
